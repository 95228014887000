const platformLinksContent = [
  {
    page: "Ark Pro",
    to: "/ARK-Pro",
  },
  {
    page: "Iress",
    to: "/Iress",
  },

  // {
  //   page: "Meta Trade 5",
  //   to: "/Meta-Trade-5",
  // },
];

export default platformLinksContent;
